import React, {useState, useRef, useEffect, useCallback, useContext} from 'react';
import { Formik, Field, Form, validateYupSchema } from 'formik';
import { priceBaseContext } from '../../hooks/UsePriceBase';
import mealFactory from '../../factory/mealFactory';
import orderDetailFactory from '../../factory/orderDetailFactory';
import '../style/Root.css';
import './Addons.css';
import { select } from 'async';
import { values } from 'lodash';
const removeSpace = (string)=>{
    return string.replace(/\s+/g, '');
}
function roundUp(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.ceil(value * multiplier) / multiplier;
}
function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
}
export default function AddonsNew (props){
    const {
        lang,
        meal,
        addOrderDetail,
        itemStacks,
        closeAddons, 
        selectedOrderDetail,
        page,
        style
    } = props;
    const priceBase = useContext(priceBaseContext);
    const [mealItemList, setMealItemList] = useState([]);
    const [errors, setErrors] = useState({});
    const [subtotal, setSubtotal] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectingOptionIndex, setSelectingOptionIndex] = useState([]);
    const mealFC = mealFactory(itemStacks);
    const [noOptionIsShown, setNoOptionIsShown] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [myItemStacks, setMyItemStacks] = useState([]);
    const [specialInstruction, setSpecialInstruction] = useState("");
    const [counter, setCounter] = useState(0);
    const [ref, setRef] = useState();
    // const buttonStyle = {
        
    // }
    const detailFC = orderDetailFactory(priceBase);
    const tempOrderDetail = {meal, itemDetails: itemDetails.filter(obj=>obj)};
    const orderPrice = fixed(detailFC.getOrderDetailTotalPrice(tempOrderDetail),1);
    
    const handleSpecialInstruction = (e) => {
        setSpecialInstruction(e.target.value);
    }
    useEffect(() => {
        if(page){
            setSelectedIndex(0);
            let arr = [];
            for (const [index, itemStack] of itemStacks.entries()){
                if(mealFC.isStandardItem(itemStack)){
                    arr[index] = {...mealFC.createItemDetail(itemStack.id, false, itemStack)};
                    handleSelectItem(index, {...mealFC.createItemDetail(itemStack.id, false, itemStack)}, itemStack);
                }
                // const standardItem = mealFC.getStandardItemWithIndex(itemStack);
            }
            setItemDetails(arr);
            // setItemDetails([]);
            setMyItemStacks([]);
            setErrors({});
            setSpecialInstruction("");
            const addons = document.getElementById("app-addons");
            addons.scrollTo({top: 0, behavior: "auto"})
        }
    }, [page]);
    useEffect(() => {
        setMyItemStacks(itemStacks);
    }, [itemStacks]);
    useEffect(() => {
        if(selectedOrderDetail){
            setItemDetails(selectedOrderDetail.itemDetails);
            setSpecialInstruction(selectedOrderDetail.specialInstruction);
            setMyItemStacks(selectedOrderDetail.myItemStacks);
        }
    }, [selectedOrderDetail, page]);

    const validateItemDetails = () => {
        let newErrors = {};
        for (const [index, itemStack] of myItemStacks.entries()){
            const numberOfItemDetail = itemDetails.filter(obj=>obj?.itemStack.id===itemStack.id).length;
            const minSelection = mealFC.getItemStackMinSelection(itemStack);
            if(numberOfItemDetail < minSelection){
                if(!itemDetails[index]) newErrors[index] = "需選";
            }
        }
        setErrors(newErrors);
        setSelectedIndex(-1);
        return [!Object.entries(newErrors).length, newErrors];
    }
    const selectingItemIndex = (index, itemStack) => {
        setSelectedIndex(index);  
        // initSelectingOptionIndex(itemStack);
    };
    const initSelectingOptionIndex = (itemStack) => {
        if(itemStack.optionStacks.length===1){
            setSelectingOptionIndex([0]);
        }else{
            setSelectingOptionIndex([...Array(itemStack.optionStacks.length).keys()]);
        }
    }
    const handleSelectItem = (index, itemDetail, itemStack)=>{
        const maxSelection = itemStack&&mealFC.getItemStackMaxSelection(itemStack);
        let newItemDetails = [...itemDetails];
        let newItemStacks = [...myItemStacks];

        if(maxSelection > 1 || maxSelection === -2){
            newItemDetails[index] = itemDetail;
            const numberOfItemDetail = newItemDetails.filter(obj=>obj?.itemStack.id===itemStack.id).length;
            const numberOfItemStack = newItemStacks.filter(obj=>obj?.id===itemStack.id).length;

            //if there itemDetails[index] is not undefined means that user is selecting the same item, so no adding
            if(!itemDetails[index]){
                if(numberOfItemDetail < maxSelection){
                    newItemDetails.splice(index+1, 0, undefined);
                }
                if(numberOfItemStack < maxSelection){
                    newItemStacks.splice(index+1, 0, itemStack);
                }
                if(maxSelection === -2) {
                    newItemDetails.splice(index+1, 0, undefined);
                    newItemStacks.splice(index+1, 0, itemStack);
                }
            }
        }else{
            newItemDetails[index] = itemDetail;    
        }

        setItemDetails(newItemDetails);
        setMyItemStacks(newItemStacks);
    };

    const handleUnselectItem = (targetIndex, itemStack) => {
        const maxSelection = mealFC.getItemStackMaxSelection(itemStack);
        const lastIndexOfDetail = itemDetails.map(obj=>obj?.itemStack.id===itemStack.id).lastIndexOf(true);
        const itemDetailsTail = itemDetails.slice(targetIndex+1);
        const itemDetailsHead = itemDetails.slice(0, targetIndex);

        let newItemDetails = [...itemDetails];
        let newItemStacks = [...myItemStacks];
        
        //to update the itemDetails and myItemStack to make sure both index is representing the same info
        //this very important because it is highly rely on index to show item and itemDetail base on index
        if(maxSelection > 1|| maxSelection === -2){
            //if target item is the last item that has detail
            if(targetIndex === lastIndexOfDetail){
                // console.log("is the lastIndexOfDetail");
                newItemDetails[targetIndex] = undefined;
                if(newItemStacks[targetIndex+1] && newItemStacks[targetIndex+1].id===itemStack.id){
                    newItemDetails.splice(targetIndex+1, 1);
                    newItemStacks.splice(targetIndex+1, 1);
                }
            }else{
                //shift all itemdetails to left by one
                //and replace the last detail (with same item id) with undefined
                newItemDetails = [...itemDetailsHead, ...itemDetailsTail];
                const lastIndexOfDetail = newItemDetails.map(obj=>obj?.itemStack.id===itemStack.id).lastIndexOf(true);
                newItemDetails.splice(lastIndexOfDetail+1, 0, undefined);

                //if the difference between the number of item details and number of item is greater than 1
                //remove one item in itemstack and also one empty(which is undefined) item detail
                const numberOfItemDetail = newItemDetails.filter(obj=>obj?.itemStack.id===itemStack.id).length;
                const numberOfItemStack = newItemStacks.filter(obj=>obj?.id===itemStack.id).length;
                if(numberOfItemStack - numberOfItemDetail > 1){
                    const lastIndexOfItem = newItemStacks.map(obj=>obj.id===itemStack.id).lastIndexOf(true);
                    newItemDetails.splice(lastIndexOfItem, 1);
                    newItemStacks.splice(lastIndexOfItem, 1);
                }
                
            }
            setItemDetails(newItemDetails);
            setMyItemStacks(newItemStacks);
        }else{
            newItemDetails[targetIndex] = undefined;
            setItemDetails(newItemDetails);
        }
        // setSelectedIndex(targetIndex);
        setSelectedIndex(-1);
    }

    const updateItemDetails = (index, itemDetail)=>{
        let arr = [...itemDetails];
        arr[index] = itemDetail;    
        setItemDetails(arr);
    };

    const removeItemDetailsOption = (itemIndex, optionIndex)=>{
        if(optionIndex === -1){
            let arr = [...itemDetails];
            // itemDetails.map(itemDetail=>itemDetail.optionStacks[])
            const newOptionStacks = itemDetails[itemIndex].optionStacks.map(option=>({...option, content: []}));
            arr[itemIndex].optionStacks = newOptionStacks;
            setItemDetails(arr);
        }else{
            let arr = [...itemDetails];
            arr[itemIndex].optionStacks[optionIndex].content = [];
            setItemDetails(arr);
        }
    };

    const getItemDetailByIndex = (index, itemStack) => {
        if (!itemDetails[index]) return false;
        if (itemDetails[index].itemStack.id !== itemStack.id) return false;
        return itemDetails[index];
    };
    const getOptionDetailByIndex = (itemIndex, optionIndex) => {
        return itemDetails[itemIndex]? itemDetails[itemIndex].optionStacks[optionIndex]:false;
    };

    const getDetailPosition = (itemIndex, id) => {
        const numberOfDetailBeforeTarget = itemDetails.slice(0, itemIndex).filter(obj=>obj?.itemStack.id === id).length;
        return numberOfDetailBeforeTarget+1;
    }

    const getNumberOfSelected = (targetItemStack) => {
        return itemDetails.filter(ele=>ele).filter(({itemStack})=>itemStack.id===targetItemStack.id).length;
    };
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
      
        context.font = font || getComputedStyle(document.body).font;
      
        return context.measureText(text).width;
      }
    // console.log(errors);
    // console.log(itemDetails);
    // console.log(myItemStacks);

    return (
    <div>
        <div className="head-bar mb:xs"/>
        {/* <div style={{height: "1000px"}}></div> */}
        <div className="c:row flex:1">
            {myItemStacks.map((itemStack, itemIndex)=>{
                const isStandardItem = mealFC.isStandardItem(itemStack);
                const itemParent = mealFC.getItemParent(itemStack);
                {/* const itemChildren = !isStandardItem? mealFC.getItemChildren(itemStack).sort((a, b) => b[lang].length - a[lang].length):[]; */}
                const itemChildren = !isStandardItem? mealFC.getItemChildren(itemStack):[];
                const itemDetail = getItemDetailByIndex(itemIndex, itemStack);
                const detailPosition = getDetailPosition(itemIndex, itemStack.id);
                const selectedItem = isStandardItem? true:itemDetail&&mealFC.getItemChildren(itemDetail.itemStack)[0];
                const isSelected = selectedIndex===itemIndex;
                const selectedItemOptionStacks = selectedItem&&itemStack.optionStacks.filter(optionStack=>optionStack.parent.includes(selectedItem.id)||optionStack.parent.includes(itemParent.id));
                return (
                <div key={`item-wrap-${itemIndex}`} className="c:row">
                    {<ItemParentButton 
                        errors={errors}
                        detailPosition={detailPosition}
                        selectedIndex={selectedIndex}
                        selectingItemIndex={selectingItemIndex}
                        getNumberOfSelected={getNumberOfSelected}
                        lang={lang}
                        priceBase={priceBase}
                        isStandardItem={isStandardItem}
                        isSelected={isSelected}
                        itemDetail={itemDetail}
                        itemParent={itemParent}
                        itemIndex={itemIndex}
                        itemStack={itemStack}
                        selectedItem={selectedItem}
                        setSelectingOptionIndex={setSelectingOptionIndex}
                        handleUnselectItem={handleUnselectItem}
                        mealFC={mealFC} 
                    />}
                    <div className={`addones-item-child-grid ${itemChildren.length> 8 ? 'small-grid-container':''}`}>
                        {isSelected&&itemChildren.map(item=>{
                            return (
                                <ItemChildButton
                                    setNoOptionIsShown={setNoOptionIsShown}
                                    item={item}
                                    itemCount = {itemChildren.length}
                                    lang={lang} 
                                    priceBase={priceBase}
                                    mealFC={mealFC} 
                                    itemIndex={itemIndex} 
                                    itemStack={itemStack} 
                                    handleUnselectItem={handleUnselectItem}
                                    selectedItem={selectedItem}
                                    handleSelectItem={handleSelectItem} 
                                    initSelectingOptionIndex={initSelectingOptionIndex} 
                                />
                            )
                        })
                        }
                        
                    </div>
                    <div className="option-button-wrapper">
                    {itemDetail&&selectedIndex===itemIndex&&selectedItemOptionStacks.length>0&&
                        <div className="no-option-btn-wrap">
                            {!noOptionIsShown[itemIndex]? 
                                <button 
                                    onClick={()=>{
                                        // setSelectingOptionIndex([]);
                                        // removeItemDetailsOption(itemIndex, -1);
                                        // setNoOptionIsShown((origin)=>{
                                        //     let copy = [...origin];
                                        //     copy[itemIndex] = true;
                                        //     return copy;
                                        // });
                                        // selectingItemIndex(itemIndex+1, itemStack);
                                    }} className="no-option-btn">
                                    <i className="material-icons">settings</i>
                                </button>:
                                <button 
                                    onClick={()=>{
                                        // setSelectingOptionIndex([]);
                                        // removeItemDetailsOption(itemIndex, -1);
                                        // setNoOptionIsShown((origin)=>{
                                        //     let copy = [...origin];
                                        //     copy[itemIndex] = true;
                                        //     return copy;
                                        // });
                                        // selectingItemIndex(itemIndex+1, itemStack);
                                    }} className="no-option-btn">
                                    <i className="material-icons">settings</i>
                                </button>
                            //     <button 
                            //     onClick={()=>{
                            //         setSelectingOptionIndex([]);
                            //         removeItemDetailsOption(itemIndex, -1);
                            //         setNoOptionIsShown((origin)=>{
                            //             let copy = [...origin];
                            //             copy[itemIndex] = true;
                            //             return copy;
                            //         });
                            //         selectingItemIndex(itemIndex+1, itemStack);
                            //     }} className="no-option-btn">
                            //     <i className="material-icons">settings</i>
                            //     <h6>
                            //         無其他要求
                            //     </h6>
                            // </button>
                                // : <button 
                                //     onClick={()=>{
                                //         initSelectingOptionIndex(itemStack);
                                //         setNoOptionIsShown((origin)=>{
                                //             let copy = [...origin];
                                //             copy[itemIndex] = false;
                                //             return copy;
                                //         });
                                //     }} className="no-option-btn">
                                //     <i className="material-icons">settings</i>
                                //     <h6>
                                //         顯示其他要求
                                //     </h6>
                                // </button>
                            }
                        </div>
                    }
                    <div className="option-stack-wrapper">
                    {!noOptionIsShown[itemIndex]&&itemDetail&&selectedItemOptionStacks.map((optionStack, optionIndex)=>{
                        const optionParent = mealFC.getOptionParent(optionStack);
                        const optionDetail = getOptionDetailByIndex(itemIndex, optionIndex);
                        const selectedOption = optionDetail&&mealFC.getOptionChildren(itemDetail.optionStacks[optionIndex])[0];
                        const selectedOptionPrice = parseFloat(selectedOption?.[priceBase]); //if dineInPrice is undefine, will return NaN
                        const executeUnselect = () => {
                            removeItemDetailsOption(itemIndex,  optionIndex);
                            setSelectingOptionIndex(origin=> origin.filter(ele=>ele!=optionIndex));
                        }
                        return (
                        <div className="c:row" key={`option-${optionIndex}`}>
                            {selectedOption?
                                <div className="trash-wrapper child-option">
                                <button 
                                key={optionParent.id}
                                type="button"
                                className="option-parent addons-option-parent-selected c:col c:between gap:xs"
                                onClick={()=>{
                                    selectingItemIndex(itemIndex, itemStack);
                                    if (selectingOptionIndex.includes(optionIndex)){
                                        setSelectingOptionIndex(origin=> origin.filter((i)=> i !== optionIndex));
                                    }else{
                                        setSelectingOptionIndex(origin=> [...origin, optionIndex]);
                                    }
                                }}
                                >
                                    <div className="c:row">
                                        <h5 className="text-align-left">
                                        {optionParent[lang]}
                                        </h5>
                                        {optionParent.secName&&<h5 className="option-child secondary-name">
                                                {optionParent.secName}
                                        </h5>}
                                    </div>
                                    <div className="c:col">
                                        <h5 className="selected-item-name">{selectedOption[lang]}</h5>
                                        <h5>{!isNaN(selectedOptionPrice)&&selectedOptionPrice!==0&&` +$${selectedOptionPrice}`}</h5>
                                    </div>
                                </button>
                                            <button className="trash child-option"
                                            onClick={executeUnselect}
                                            >
                                                <i className="material-icons">delete</i>
                                            </button>
                                            </div>
                                :selectedIndex===itemIndex?<button 
                                key={optionParent.id}
                                type="button"
                                className="option-parent addons-option-parent"
                                onClick={()=>{
                                    if (selectingOptionIndex.includes(optionIndex)){
                                        setSelectingOptionIndex(origin=> origin.filter((i)=> i !== optionIndex));
                                    }else{
                                        setSelectingOptionIndex(origin=> [...origin, optionIndex]);
                                    }
                                }}
                                >
                                    <div className="c:row">
                                        <h5>
                                        {optionParent[lang]}
                                        {!selectingOptionIndex.includes(optionIndex)&&' (點撃設定)'}
                                        </h5>
                                        {optionParent.secName&&<h5 className="option-child secondary-name">
                    
                                        </h5>}
                                    </div>
                                </button>
                                :null
                            }
                            <div className="addones-option-child-grid">
                            {selectingOptionIndex.includes(optionIndex)&&selectedIndex===itemIndex&&optionStack.content.filter(obj=>!obj.isSelectable).map(optionChild=>{
                                const isSelectedOptionChild = selectedOption?.id===optionChild.id;
                                const optionChildPrice = parseFloat(optionChild?.[priceBase]); //if price is undefine, will return NaN
                                const executeSelect = () => {
                                    let tempItemDetail = itemDetails[itemIndex];
                                    let tempOptionStack = {...optionStack, content: [optionParent, optionChild]};
                                    tempItemDetail.optionStacks[optionIndex] = tempOptionStack;
                                    updateItemDetails(itemIndex, tempItemDetail);
                                    setSelectingOptionIndex(origin=> origin.filter(ele=>ele!=optionIndex));
                                }
                                const executeUnselect = () => {
                                    let tempItemDetail = itemDetails[itemIndex];
                                    let tempOptionStack = {...optionStack, content: [optionParent, optionChild]};
                                    tempItemDetail.optionStacks[optionIndex] = tempOptionStack;
                                    removeItemDetailsOption(itemIndex,  optionIndex);
                                    setSelectingOptionIndex(origin=> origin.filter(ele=>ele!=optionIndex));
                                }
                                return (
                                    <button 
                                    key={optionChild.id}
                                    type="button" 
                                    className={`${isSelectedOptionChild? "addons-option-child-selected":"addons-option-child"} c:col c:center c:middle gap:xs`}
                                    onClick={isSelectedOptionChild? executeUnselect:executeSelect}
                                    >
                                        <div className="c:row flex:1">
                                            <h6 className="addons-option-child-name">{optionChild[lang]}</h6>
                                            {optionChild.secName&&<h5 className="option-child secondary-name">
                                                {optionChild.secName}
                                            </h5>}
                                        </div>
                                        <h6>{!isNaN(optionChildPrice)&&optionChildPrice!==0&&` +$${optionChildPrice}`}</h6>
                                    </button>
                                )
                            })}
                            </div>
                        </div>
                        )
                    })}
                    </div>
                    </div>
                </div>
                );
                
            })}
            {/* <div style={{height: "1000px"}}></div> */}
            <h6 class="addons-option-title pt:sm pb:xs f:400">特別要求</h6>
            <textarea 
            id="special"
            name="special"
            onChange={handleSpecialInstruction}
            value={specialInstruction}
            class="addons-special w:full shadow bg:white r:xs"
            placeholder="例： 不要蔥/不要花生"
            />
            {/* <pre>counter:{counter}</pre>
            <button type="button" onClick={()=>{setCounter(i=>i+1)}}>
                counter
            </button> */}
            <div className="addons-sized-box"></div>
            
        </div>
        <div 
            onClick={()=>{
                const [isValid, result] = validateItemDetails();
                if(isValid){
                    addOrderDetail({meal, itemDetails, specialInstruction, myItemStacks, selectedOrderDetail});
                    closeAddons();
                }else{
                    alert('套餐尚未點齊');
                }
                // setCounter(i=>i+1);
            }} 
            className="addons-submit-btn-wrap" style={style.mobile}>
            <div className="c:row c:middle c:center h:full">
                <h4 className="f:white">加入餐單</h4>
                {/* <h4>{counter}</h4> */}
                <h4 className="addons-price f:white">${orderPrice}</h4>
            </div>
        </div>

    </div>
    );
}

const ItemParentButton = ({lang, errors, priceBase, isStandardItem, detailPosition, selectedIndex, selectingItemIndex, getNumberOfSelected, isSelected, itemDetail, itemParent, itemIndex, itemStack, selectedItem, handleUnselectItem, mealFC}) => {
    // const myRef = useRef(0);
    const toggleIsClicked = () => {
        executeScroll();
        selectingItemIndex(itemIndex, itemStack);
    };
    const executeUnselect = () => {
        handleUnselectItem(itemIndex, itemStack);
    };
    const selectedItemPrice = parseFloat(selectedItem?.[priceBase]); //if price is undefine, will return NaN
    const maxSelection = itemParent.maxSelection;
    const addons = document.getElementById("app-addons");
    const minSelection = mealFC.getItemStackMinSelection(itemStack);
    const executeScroll = () => {
        const y = itemIndex*50;
        addons.scrollTo({top: y, behavior: 'smooth'});
    };
    return(
    <div>
        {itemDetail?
            isStandardItem&&itemDetail.optionStacks.length===0?
            !itemParent[lang].replace(/\s/g, '').length ? 
                <div /> :
                <div className="standard-item c:col gap:xs">
                    <div className="c:row">
                        <h5 >
                            {itemParent[lang]}{(maxSelection>1||maxSelection==-2)&&` (${detailPosition})`}
                        </h5>
                        {itemParent.secName&&<h5 className="secondary-name">
                            {itemParent.secName}
                        </h5>}
                    </div>
                    <div className="c:col flex:1 selected-item-info">
                        <h5 className="selected-item-name">{selectedItem[lang]}</h5>
                        {isStandardItem&&<h5 className="selected-item-name">必選</h5>}
                        <h5>{!isNaN(selectedItemPrice)&&selectedItemPrice!==0&&` +$${selectedItemPrice}`}</h5>
                    </div>
                </div>
            :<div className="trash-wrapper">
                <button 
                id={itemParent.id}
                key={itemParent.id}
                type="button"
                className={
                    `${(selectedIndex===itemIndex&&itemStack.optionStacks.length>0)? "addons-item-parent-selecting"
                            :"addons-item-parent-selected"} c:col gap:xs`}
                onClick={toggleIsClicked}
                >
                <div className="c:row">
                    <h5 className="text-align-left">
                        {itemParent[lang]}{(maxSelection>1||maxSelection==-2)&&` (${detailPosition})`}
                    </h5>
                    {itemParent.secName&&<h5 className="secondary-name">
                    {itemParent.secName}
                    </h5>}
                </div>
                <div className="c:col flex:1 selected-item-info">
                    <h5 className="selected-item-name">{selectedItem?.[lang]}</h5>
                    {isStandardItem&&<h5 className="selected-item-name">必選</h5>}
                    <h5>{!isNaN(selectedItemPrice)&&selectedItemPrice!==0&&` +$${selectedItemPrice}`}</h5>
                </div>
            </button>
            {
                !isStandardItem && 
                <button className="trash"
                    onClick={executeUnselect}>
                    <i className="material-icons">delete</i>
                </button>
            }

            </div>
            :<button 
            // disabled={!itemStack.optionStacks.length}
            id={itemParent.id}
            key={itemParent.id}
            type="button"
            className={
                    (isSelected)?
                        `addons-item-parent`
                        :`${(errors[itemIndex])&&"item-error"} addons-item-parent-unselect`
                }
            onClick={toggleIsClicked}
            >   
                <div className="c:row">
                    <h5>
                        {itemParent[lang]}{maxSelection>0&&minSelection-getNumberOfSelected(itemStack)>0 ? ` ( 需選${minSelection-getNumberOfSelected(itemStack)} )` : ' (點擊追加)' }
                        {/*{itemParent[lang]}{maxSelection>1&&` ( 可選${itemParent.maxSelection-getNumberOfSelected(itemStack)} )`} */}
                    </h5>
                    {itemParent.secName&&
                    <h5 className="secondary-name">
                        {itemParent.secName}
                    </h5>}
                </div>
            </button>}
    </div>
    );
}

const ItemChildButton = ({lang, item, itemCount, setNoOptionIsShown, priceBase, handleUnselectItem, handleSelectItem, initSelectingOptionIndex, mealFC, itemIndex, itemStack, selectedItem}) => {
    const isClicked = selectedItem?.id===item.id;
    const itemChildPrice = parseFloat(item?.[priceBase]); //if price is undefine, will return NaN
    const executeUnselect = () => {
        handleUnselectItem(itemIndex, itemStack);
    };
    const executeSelect = () => {
        const itemDetail = {...mealFC.createItemDetail(itemStack.id, item, itemStack)};
        handleSelectItem(itemIndex, itemDetail, itemStack);
        initSelectingOptionIndex(itemStack);
        setNoOptionIsShown((origin)=>{
            let copy = [...origin];
            copy[itemIndex] = false;
            return copy;
        });
    };
    return (
        <button 
            type="button" 
            className={`${isClicked? "addons-item-child-selected":"addons-item-child"} ${itemCount > 8 ? "small-box" : ""} c:col c:center c:middle`}
            onClick={isClicked? executeUnselect : executeSelect}
            >
                <div className="c:row">
                    <h6 className="addons-item-child-name">{item[lang]}</h6>
                    {item.secName&&
                    <h5 className="item-child secondary-name">
                        {item.secName}
                    </h5>}
                </div>
                <h6>{!isNaN(itemChildPrice)&&itemChildPrice!==0&&` +$${itemChildPrice}`}</h6>
        </button>
    );
}

