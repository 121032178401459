import '../style/Root.css';
import './InfoBar.css';

function InfoBar(props){
    const {openCart, style} = props;
    return (
    // <div class="app-info-bar w:full bg:pink">
    //     <div class="info-bar shadow bg:white r:lg c:col c:middle c: between" style={style}>
    //         <div onClick={openCart} class="info-cart-btn w:1/2 center f:grey f:500 touch">查看購物籃</div>
    //         <div class="w:1/2 c:col c:middle">
    //             <div class="info-total f:grey">總共 $78.00</div>
    //             <div class="info-qty bg:orange center f:white">1</div>
    //         </div>
    //     </div>
    // </div>
    <div></div>
    );
}

export default InfoBar;