import React, {useState, useRef, useEffect} from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Shop from './components/shop/Shop';
import Addons from './components/addons/Addons';
import NewCart from './components/cart/NewCart';
import Loading from './components/loading/Loading';
import { ErrorPage } from './components/shop/Shop';

function App() {
  return (
    <Switch>
      <Route exact path="/restaurant/dinein/:token">
        <Shop/>
      </Route>
      <Route path="/" render={()=><ErrorPage/>} />
      {/* <Route exact path="/restaurant/dinein/:refId/editItem">
        <Addons/>
      </Route> */}
      {/* <Route exact path="/">
        <Loading/>
      </Route> */}
    </Switch>
  );
}

export default App;
