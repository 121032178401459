import './style/Root.css';

export function HeadBar({operation, title, className, secTitle}){
    return(
        <div className={`${className} head-bar w:full bg:head-bar shadow c:col c:middle`}>
            <div onClick={operation} class="head-btn">
                <i class="f:icon f:black material-icons-outlined">
                arrow_back_ios
                </i>
            </div>
            <div className="c:row flex:1">
                <h4 className="center f:500">{title}</h4>
                {secTitle&&<h5 className="center secondary-name">{secTitle}</h5>}
            </div>
            <div class="head-btn untouch"/>
        </div>
    );
}