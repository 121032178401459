import React, {useEffect, useState, useContext} from 'react';
import { dataContext } from './UseData';

const useAddons = (mealId) => {
    const dineInDetail = useContext(dataContext)||undefined;
    let meal={};
    let stackIds=[];
    let itemStacks=[];
    let myItemStacks=[];
    let itemIdArr=[];
    let optionStacks=[];
    if(dineInDetail&&mealId){
        meal = dineInDetail.menu.listOfMeal[mealId];
        stackIds = [...meal.stack];
        itemStacks = dineInDetail.menu.itemStacks.filter(obj=>stackIds.includes(obj.id));
        itemIdArr = itemStacks.map(itemStack=>Object.keys(itemStack.content)).flat();
        optionStacks = dineInDetail.menu.optionStacks.filter(optionStack=>optionStack.parent.some(ele=>itemIdArr.includes(ele)));
        for (const itemStack of itemStacks){
            let myItemStack = {...itemStack};
            const itemIds = Object.keys(itemStack.content);
            const thisOptionStack = optionStacks.filter(optionStack=>optionStack.parent.some(id=>itemIds.includes(id)));
            myItemStack.optionStacks = thisOptionStack;
            myItemStacks.push(myItemStack);
        }
    }

    return {
        meal, 
        itemStacks: myItemStacks,
    };
};


export default useAddons;