import React, { useContext, useMemo, useState } from 'react';
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";

import '../style/Root.css';
import './Checkout.css';
import {HeadBar} from '../Elements';
import { Formik } from 'formik';
import { widthContext } from '../../hooks/UseWindowWidth';
import {stripeCheckout} from '../../api/checkoutApi';



function Checkout(props){
    const isWebView = useContext(widthContext);
    const {openOrderReview, closeCheckout, cartTotal, style} = props;
    const pk ='pk_test_51J7ab0E9OVkQ0RQPM1xRIzIScKTv1JJhVbpE2fqsbVGBVDTHs92xXqGjTiU9ehtKH348LixGkneYbgkJyDiJwBHJ00FJLj8PXi';
    // const pk = 'pk_test_51JKvs82SFCuJVeGbLMuwVQkGOVqKxx2216PY6h00KgftjjwbEVZY5xxyeIecj8LdXmUW1OfbPid4vp3GLAzgDdm900V4CEilTl';
    const stripePromise = loadStripe(pk);
    return(
        <Elements stripe={stripePromise}>
            {(isWebView)?
            <div class="modal" style={{...style.web, "z-index": "100"}}>
                <div class="modal-content c:row">
                    <div class="modal-head py:sm">
                        <div onClick={closeCheckout} class="head-btn close-btn bg:grey">
                                <i class="f:icon f:grey material-icons-outlined">
                                    close
                                </i>
                        </div>
                    </div>
                    <CheckoutBody openOrderReview={openOrderReview} cartTotal={cartTotal}/>
                </div>
            </div>:
            <div class="app-checkout" style={style.mobile}>
                <HeadBar className="checkout-head" operation={closeCheckout} title="Checkout"/>
                <div class="head-bar"/>

                <CheckoutBody openOrderReview={openOrderReview} cartTotal={cartTotal}/>
            </div>}
        </Elements>
    );
}

const options = {
    hidePostalCode: true,
    style: {
    base: {
        fontSize: "20px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
        color: "#aab7c4"
        }
    },
    invalid: {
        color: "#fa755a"
    }
    }
};
const isEmpty = (string) => {
    if (/\A\s*\z/.test(string)) return true;
	if (string&&`${string}`.trim() === '') return true;
	else return false;
};

const validateForm = (values) => {
    let errors = {};

    if(values.name === "") errors.name = "必填";
    if(values.email === "") errors.email = "必填";
    if(values.tel === "") errors.tel = "必填";

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

const CheckoutBody = ({openOrderReview, cartTotal}) => {
    const stripe = useStripe();
    const elements = useElements();
    let localURL = "http://localhost:5000/foodorderingsystem-5bcdb/us-central1/api";
    const [values, setValues] = useState({name: "", email: "", tel: ""});
    const [errors, setErrors] = useState({});
    const [cardInputError, setCardInputError] = useState({});
    const nameChangeHandler = (event) => setValues(obj=>({...obj, name: event.target.value}));
    const emailChangeHandler = (event) => setValues(obj=>({...obj, email: event.target.value}));
    const telChangeHandler = (event) =>  setValues(obj=>({...obj, tel: event.target.value}));


    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
        
        const [isValid, formErrors] = validateForm(values);
        if(!isValid){
            setErrors(formErrors);
            return;
        }
        setErrors({});
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if(error){
            setCardInputError(error);
            return;
        }
        setCardInputError({});
        
        const intent_secret = await axios.post(localURL + '/checkout', {cartTotal: cartTotal});
        // console.log(intent_secret.data);
        stripe.confirmCardPayment(
            intent_secret.data.client_secret,
            // "pi_3JKwVt2SFCuJVeGb147SfD56_secret_ckhfecOZ6GyxJ42HbFZDFOPyD",
            {
                payment_method: {card: cardElement}
            }
            ).then(function(result) {
            if (result.error) {
                alert(JSON.stringify(result.error, null, 2));
                alert(JSON.stringify(result, null, 2));
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                    alert(JSON.stringify(result, null, 2));
                }
                
            }
            });
    
        // if (error) {
        //     alert(JSON.stringify(error));
        // } else {
        //     await stripeCheckout({paymentMethod});
        //     // const [client_secret, error] = await stripeCheckout({paymentMethod});
        //     // if (client_secret) {
        //     //     const result = await stripe.confirmCardPayment(client_secret, {
        //     //       payment_method: {
        //     //         card: cardNumber
        //     //       }
        //     //     });
        //     //     if (result.paymentIntent.status === "succeeded") {
        //     //       window.alert("成功", "", "success");
        //     //       //TODO send another request to update backend customer status
        //     //     } else {
        //     //       window.alert("付款失敗");
        //     //     }
        //     // }

        // }
    };
  
    return (
        <form onSubmit={handleSubmit}>
            <div class="checkout-body h:full w:full c:row p:sm gap:sm bg:grey r:sm">
                <div class="c:col c:between align-items-center px:xs">
                    <h4>Contact</h4>
                    {/* <div class="checkout-edit-btn btn bg:white border:all r:xs">
                        <i class="f:2xl f:light-grey material-icons-outlined">
                                edit
                        </i>
                    </div> */}
                </div>
                <div class="checkout-info-box c:row bg:white r:sm shadow p:sm gap:sm">
                    <div>
                        <div className="c:col c:between"> 
                            <label for="tel">Phone number</label>
                            {errors.tel && <p class="f:orange">{errors.tel}</p>}
                        </div>
                        <input type="text" id="tel" name="tel" placeholder="e.g. 9321 7321" style={{width: "100%"}} onChange={telChangeHandler}/>
                    </div>
                    <div>
                        <div className="c:col c:between"> 
                            <label for="email">Email</label>
                            {errors.email && <p class="f:orange">{errors.email}</p>}
                        </div>
                        <input type="text" id="email" name="email" placeholder="e.g. brian@gmail.com" style={{width: "100%"}} onChange={emailChangeHandler}/>
                    </div>
                    <div>
                        <div className="c:col c:between"> 
                            <label for="name">Name</label>
                            {errors.name && <p class="f:orange">{errors.name}</p>}
                        </div>
                        <input type="text" id="name" name="name" placeholder="e.g. Brian Cha" style={{width: "100%"}} onChange={nameChangeHandler}/>
                    </div>
                </div>
            
                <div class="c:col c:between align-items-center px:xs">
                    <h4>Payment Method</h4>
                    {/* <div class="checkout-edit-btn btn bg:white border:all r:xs">
                        <i class="f:2xl f:light-grey material-icons-outlined">
                                edit
                        </i>
                    </div> */}
                </div>
                <div class="checkout-info-box h:auto c:row bg:white r:sm shadow p:sm gap:sm">
                    <label>
                        Credit Card 
                        <CardElement options={options}/>
                    </label>
                    {cardInputError && <p class="f:orange">{cardInputError.message}</p>}
                </div>
                <div class="info-bar-wrap p:sm">
                    <button 
                    type="submit"
                    disabled={!stripe} 
                    // onClick={openOrderReview}
                    class="info-bar w:full shadow bg:white r:lg c:col c:middle"
                    >
                        <h5 class="checkout-confirm-btn w:full center f:grey f:500 touch">確認落單</h5>
                    </button>
                </div>
            </div>
        </form>
    );
  };

export default Checkout;