import React, { useEffect, useState, useReducer } from "react";

export const init = (initialState) => {
    const myObj = {...initialState};
    for (const page in myObj){
        myObj[page].style= condition[page][myObj[page].status];
    }
    return myObj;
};

export const initialState = {
    addons: {
        status: 'close',
    },
    cart: {
        status: 'close',
    },
    checkout: {
        status: 'close',
    },
    orderReview: {
        status: 'close',
    },
    webView:{
        status: 'close',
    }
};

export const condition = {
    addons: {
        close: {
            mobile:{width: "0%"},
            web:{display: "none"},
        },
        open: {
            mobile:{width: "100%"},
            web:{display: "block"},
        }
    },
    cart: {
        close: {
            mobile:{width: "0%"},
            web:{display: "none"},
        },
        open: {
            mobile:{width: "100%"},
            web:{display: "block"},
        }
    },
    checkout: {
        close: {
            mobile:{width: "0%"},
            web:{display: "none"},
        },
        open: {
            mobile:{width: "100%"},
            web:{display: "block"},
        }
    },
    orderReview: {
        close: {
            mobile:{width: "0%"},
            web:{display: "none"},
        },
        open: {
            mobile:{width: "100%"},
            web:{display: "block"},
        }
    },
    webView:{
        close: {display: "none"},
        open: {display: "block"}
    }
};

function reducer(state, action) {
  switch (action.type) {
    case 'addons':
        return {
            ...state,
            addons: {
                status: action.payload,
                style: {...condition.addons[action.payload]}
            }
        };
    case 'cart':
        return { 
            ...state,
            cart: {
                status: action.payload,
                style: {...condition.cart[action.payload]}
            }
        };
    case 'checkout':
        return { 
            ...state,
            checkout: {
                status: action.payload,
                style: {...condition.checkout[action.payload]}
            }
        };
    case 'orderReview':
        return { 
            ...state,
            orderReview: {
                status: action.payload,
                style: {...condition.orderReview[action.payload]}
            }
        };
    case 'webView':
        return { 
            ...state,
            webView: {
                status: action.payload,
                style: {...condition.webView[action.payload]}
            }
        };
    default:
      throw new Error();
  }
}

const useOverlay = () => {
    const [state, dispatch] = useReducer(reducer, initialState, init);
    return [state, dispatch];
}

export default useOverlay;