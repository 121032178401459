import React, { useEffect, useState, useContext } from "react";

const usePriceBase = (currentPriceBase) => {
    const [priceBase, setPriceBase] = useState(currentPriceBase);

    const changePriceBase = (change) => {
        setPriceBase(change);
    }

    return [priceBase, changePriceBase];
};

export const priceBaseContext = React.createContext();

export default usePriceBase;