import { useContext } from 'react';
import { priceBaseContext } from '../hooks/UsePriceBase';
import mealFactory from './mealFactory';

const orderDetailFactory = (priceBase) => {
    const myMeal = mealFactory();
    const getPrice = (obj) => obj[priceBase]? parseFloat(obj[priceBase]):0;

    const getOrderDetailTotalPrice = (orderDetail) => {
        let total = parseFloat(orderDetail.meal[priceBase]);
        
        for (const itemDetail of orderDetail.itemDetails){
            if(!itemDetail) continue;
            const itemStack = itemDetail.itemStack;
            const isStandardItem = myMeal.isStandardItem(itemStack);
            const itemChild = !isStandardItem&&myMeal.getItemChildren(itemStack)[0];
            // if(isStandardItem){
            //     continue;
            // }
            // console.log(priceBase);
            if(itemChild) total+=getPrice(itemChild);
            // if(itemChild) console.log(itemChild);
            
            for (const optionStack of itemDetail.optionStacks){
                if(!optionStack.content.length) continue;
                const optionChild = myMeal.getOptionChildren(optionStack)[0];
                // console.log(optionChild);
                if(optionChild) total+=getPrice(optionChild);
            }
        }
        return total;
    };

    return {
        getOrderDetailTotalPrice,
    };
}

export default orderDetailFactory;