import axios from 'axios';
import React, {useEffect, useState, useContext, useCallback} from 'react';
import { v4 as uuidv4} from 'uuid';
import { dataContext } from '../hooks/UseData';
import useLanguage, {languageContext} from '../hooks/UseLanguage';
import { postOrder } from '../api/checkoutApi';
import orderDetailFactory from '../factory/orderDetailFactory';


const useOrderDetail = () => {
    const dineInDetail = useContext(dataContext);
    const lang = useContext(languageContext);
    const [orderDetails, setOrderDetails] = useState([]);
    const addOrderDetail = ({meal, itemDetails, specialInstruction, myItemStacks, selectedOrderDetail}) => {
         //suppose there is no undefined obj in itemDetails, because it should not be submitted if there is user missed item
        // const filtered = itemDetails.filter(obj=>obj!==undefined);
        const filtered = itemDetails;
        if(selectedOrderDetail){
            const newOrderDetail = {...selectedOrderDetail, itemDetails: filtered, specialInstruction: specialInstruction, myItemStacks: myItemStacks};
            updateOrderDetail({id: selectedOrderDetail.id, newOrderDetail})
        }else{
            const orderDetail = {meal, itemDetails: filtered, id: uuidv4(), specialInstruction, quantity: 1, myItemStacks: myItemStacks};
            setOrderDetails(origin=>[...origin, orderDetail]);
        }
        // compareTwoItemDetails(meal.id, filtered);
    }

    const updateOrderDetail = ({id, newOrderDetail}) => {
        if(newOrderDetail.quantity === 0){
            const newOrderDetails = orderDetails.filter(obj=>obj.id !== id);
            setOrderDetails(newOrderDetails);
        }else{
            const index = orderDetails.findIndex(obj=>obj.id === id);
            let newOrderDetails = [...orderDetails];
            newOrderDetails[index] = newOrderDetail;
            setOrderDetails(newOrderDetails);
        }
    }
    
    // const compareTwoItemDetails = (mealId, itemDetails) => {
    //     //return [[stack1, [item_1, subItem_5]], [stack2, [item_6, subItem_12]], ...]
    //     const itemDetail1ItemIdsList = itemDetails.map(itemDetail=>(Object.entries({[itemDetail.itemStack.id]: Object.keys(itemDetail.itemStack.content)})));
        
    //     //return [[{itemStack, optionStacks}, {itemStack, optionStacks}], [{itemStack, optionStacks},{itemStack, optionStacks},{itemStack, optionStacks}], ...]
    //     const itemDetailsListWithSameMealId =  orderDetails.filter(orderDetail=>orderDetail.meal.id === mealId).map(orderDetail=>orderDetail.itemDetails);
    //     console.log(itemDetailsListWithSameMealId);
    //     if(itemDetailsListWithSameMealId.length){
    //         for (const itemDetails2 of itemDetailsListWithSameMealId) {
                
    //             for (const itemDetail2 of itemDetails2){
    //                 const itemDetail2ItemIdsWithStackId = Object.entries({[itemDetail2.itemStack.id]: Object.keys(itemDetail2.itemStack.content)});
    //                 console.log(itemDetail2ItemIdsWithStackId);
    //                 // const itemDetail2ItemIds = Object.keys(itemDetail2.itemStack.content);
    //                 // if(itemDetail1ItemIdsList.some(arr=>arr.some(ele=>!itemDetail2ItemIds.includes(ele)))) continue;
    //             }
    //         }
    //     }
    // }

    const postOrder2db = async (token, handleSetOrdered) => {
        try {
            
            if (!orderDetails.length) throw "請先點餐";
            let orderDetailsList = [];
            for (const orderDetail of orderDetails) {
                for (let i = 0; i < orderDetail.quantity; i++) {
                    let copy = {...orderDetail};
                    let filted = orderDetail.itemDetails.filter(obj=>obj);
                    copy.itemDetails = filted;
                    orderDetailsList.push(copy);
                }
            }
            // console.log(orderDetailsList);
            // console.log(token);
            // console.log(dineInDetail);
            //filter unavailable meal
            const filted = orderDetailsList.filter(obj=>!dineInDetail.unavailableMealIds.includes(obj.meal.id));
            const order = {...dineInDetail.order, orderDetailsList: filted, isWebDineIn: true};
            // const [responseStatus, error] = await postOrder({order, token});
            const isRestaurantOnlineRes = await axios.post(`https://caterat-pos.df.r.appspot.com/dineinListenIsRestaurantOnline`, {order, token}, { timeout: 30000 })
                                                    .catch(isOnlineError=>{
                                                        throw "請重新載入並檢查餐點狀態";
                                                    });
            // if(error) throw error;
            setOrderDetails([]);
            handleSetOrdered(filted);
            if (isRestaurantOnlineRes.status === 200) {
                const unavailable = orderDetailsList.filter(obj=>dineInDetail.unavailableMealIds.includes(obj.meal.id)).map(obj=>obj.meal[lang]);
                if(unavailable.length > 0){
                    return {success: `抱歉，${unavailable.join(', ')}暫時未能提供，不過你的其他點餐通知已送達廚房，如有其他需要請與服務員聯絡`};
                }else{
                    return {success: "你的點餐通知已送達廚房"};
                }
            };
            // return {success: "你的點餐通知已送達廚房"};
        }catch(error){
            return {error: error};
        }
    }

    const numberOfOrderDetails = () => {
        return orderDetails.length > 0 ? orderDetails.reduce((quantity, orderDetail)=>quantity + orderDetail.quantity, 0):0;
    }

    return {orderDetails, addOrderDetail, updateOrderDetail, numberOfOrderDetails, postOrder2db};
};

const dummy = {
    "meal": {
        "priName": "常餐",
        "categoryId": "thisiscat1",
        "id": "tempMeal6",
        "mealCode": "000",
        "stack": [
            "stack1",
            "stack2"
        ],
        "takeawayPrice": "120",
        "dineInPrice": "100"
    },
    "itemDetails": [
        {
            "itemStack": {
                "id": "stack1",
                "content": {
                    "Item_1": {
                        "isSelectable": true,
                        "minSelection": 1,
                        "priName": "麵",
                        "isOption": false,
                        "id": "Item_1",
                        "secName": "Noodles",
                        "maxSelection": 1
                    },
                    "SubItem_5": {
                        "isSelectable": false,
                        "isOption": false,
                        "secName": "Sliced Noodle",
                        "hasParent": true,
                        "id": "SubItem_5",
                        "priName": "刀削麵"
                    }
                },
                "optionStacks": [
                    {
                        "parent": [
                            "Item_1"
                        ],
                        "content": [
                            {
                                "maxSelection": 1,
                                "minSelection": 0,
                                "secName": "Noodles amount",
                                "id": "option_parent_noodle",
                                "isSelectable": true,
                                "isOption": true,
                                "priName": "麵量"
                            },
                            {
                                "priName": "加麵底",
                                "secName": "Extra noodles",
                                "isSelectable": false,
                                "isOption": true,
                                "hasParent": true,
                                "id": "option3"
                            }
                        ]
                    }
                ]
            },
            "optionStacks": [
                {
                    "parent": [
                        "Item_1"
                    ],
                    "content": []
                }
            ]
        },
        {
            "itemStack": {
                "content": {
                    "Item_6": {
                        "priName": "飲品",
                        "isOption": false,
                        "id": "Item_6",
                        "isSelectable": true,
                        "secName": "Drinks",
                        "minSelection": 3,
                        "maxSelection": 3
                    },
                    "SubItem_12": {
                        "hasParent": true,
                        "dineInPrice": "3",
                        "takeawayPrice": "4",
                        "isSelectable": false,
                        "priName": "熱檸茶",
                        "secName": "Hot 0T",
                        "isOption": false,
                        "id": "SubItem_12"
                    }
                },
                "id": "stack2",
                "optionStacks": [
                    {
                        "parent": [
                            "SubItem_7",
                            "SubItem_8",
                            "SubItem_10",
                            "SubItem_11",
                            "SubItem_13"
                        ],
                        "content": [
                            {
                                "maxSelection": 2,
                                "isOption": true,
                                "id": "option_parent_ice",
                                "isSelectable": true,
                                "minSelection": 0,
                                "priName": "冰量",
                                "secName": "bing lern"
                            },
                            {
                                "priName": "多",
                                "isSelectable": false,
                                "takeawayPrice": "0",
                                "id": "6",
                                "hasParent": true,
                                "secName": "dor",
                                "isOption": true,
                                "dineInPrice": "0"
                            },
                            {
                                "secName": "mid",
                                "dineInPrice": "0",
                                "id": "424214312",
                                "hasParent": true,
                                "isSelectable": false,
                                "priName": "中",
                                "isOption": true,
                                "takeawayPrice": "0"
                            },
                            {
                                "priName": "少",
                                "secName": "siu",
                                "takeawayPrice": "0",
                                "hasParent": true,
                                "isOption": true,
                                "id": "7",
                                "dineInPrice": "0",
                                "isSelectable": false
                            }
                        ]
                    },
                    {
                        "content": [
                            {
                                "isSelectable": true,
                                "isOption": true,
                                "maxSelection": 1,
                                "id": "option_parent_sweet",
                                "secName": "Sweetness Level",
                                "minSelection": 0,
                                "priName": "甜度"
                            },
                            {
                                "isOption": true,
                                "priName": "多",
                                "hasParent": true,
                                "dineInPrice": "0",
                                "takeawayPrice": "0",
                                "secName": "Very Sweet",
                                "id": "Option_sweet2",
                                "isSelectable": false
                            },
                            {
                                "isOption": true,
                                "takeawayPrice": "0",
                                "isSelectable": false,
                                "priName": "少",
                                "dineInPrice": "0",
                                "hasParent": true,
                                "secName": "Less Sweet",
                                "id": "Option_sweet3"
                            }
                        ],
                        "parent": [
                            "SubItem_7",
                            "SubItem_8",
                            "SubItem_9",
                            "SubItem_11",
                            "SubItem_13"
                        ]
                    },
                    {
                        "content": [
                            {
                                "isSelectable": true,
                                "id": "option_parent_cold_hot",
                                "secName": "Cold/Hot",
                                "isOption": true,
                                "maxSelection": 1,
                                "minSelection": 0,
                                "priName": "冷/熱"
                            },
                            {
                                "priName": "冷",
                                "secName": "Cold",
                                "hasParent": true,
                                "isOption": true,
                                "isSelectable": false,
                                "dineInPrice": "2",
                                "id": "option11"
                            },
                            {
                                "secName": "Hot",
                                "isSelectable": false,
                                "hasParent": true,
                                "isOption": true,
                                "priName": "熱",
                                "id": "option12"
                            }
                        ],
                        "parent": [
                            "Item_6"
                        ]
                    }
                ]
            },
            "optionStacks": [
                {
                    "content": [
                        {
                            "isSelectable": true,
                            "id": "option_parent_cold_hot",
                            "secName": "Cold/Hot",
                            "isOption": true,
                            "maxSelection": 1,
                            "minSelection": 0,
                            "priName": "冷/熱"
                        },
                        {
                            "priName": "冷",
                            "secName": "Cold",
                            "hasParent": true,
                            "isOption": true,
                            "isSelectable": false,
                            "dineInPrice": "2",
                            "id": "option11"
                        }
                    ],
                    "parent": [
                        "Item_6"
                    ]
                },
                {
                    "content": [],
                    "parent": [
                        "SubItem_7",
                        "SubItem_8",
                        "SubItem_9",
                        "SubItem_11",
                        "SubItem_13"
                    ]
                },
                {
                    "content": [],
                    "parent": [
                        "Item_6"
                    ]
                }
            ]
        },
        {
            "itemStack": {
                "content": {
                    "Item_6": {
                        "priName": "飲品",
                        "isOption": false,
                        "id": "Item_6",
                        "isSelectable": true,
                        "secName": "Drinks",
                        "minSelection": 3,
                        "maxSelection": 3
                    },
                    "SubItem_12": {
                        "hasParent": true,
                        "dineInPrice": "3",
                        "takeawayPrice": "4",
                        "isSelectable": false,
                        "priName": "熱檸茶",
                        "secName": "Hot 0T",
                        "isOption": false,
                        "id": "SubItem_12"
                    }
                },
                "id": "stack2",
                "optionStacks": [
                    {
                        "parent": [
                            "SubItem_7",
                            "SubItem_8",
                            "SubItem_10",
                            "SubItem_11",
                            "SubItem_13"
                        ],
                        "content": [
                            {
                                "maxSelection": 2,
                                "isOption": true,
                                "id": "option_parent_ice",
                                "isSelectable": true,
                                "minSelection": 0,
                                "priName": "冰量",
                                "secName": "bing lern"
                            },
                            {
                                "priName": "多",
                                "isSelectable": false,
                                "takeawayPrice": "0",
                                "id": "6",
                                "hasParent": true,
                                "secName": "dor",
                                "isOption": true,
                                "dineInPrice": "0"
                            },
                            {
                                "secName": "mid",
                                "dineInPrice": "0",
                                "id": "424214312",
                                "hasParent": true,
                                "isSelectable": false,
                                "priName": "中",
                                "isOption": true,
                                "takeawayPrice": "0"
                            },
                            {
                                "priName": "少",
                                "secName": "siu",
                                "takeawayPrice": "0",
                                "hasParent": true,
                                "isOption": true,
                                "id": "7",
                                "dineInPrice": "0",
                                "isSelectable": false
                            }
                        ]
                    },
                    {
                        "content": [
                            {
                                "isSelectable": true,
                                "isOption": true,
                                "maxSelection": 1,
                                "id": "option_parent_sweet",
                                "secName": "Sweetness Level",
                                "minSelection": 0,
                                "priName": "甜度"
                            },
                            {
                                "isOption": true,
                                "priName": "多",
                                "hasParent": true,
                                "dineInPrice": "0",
                                "takeawayPrice": "0",
                                "secName": "Very Sweet",
                                "id": "Option_sweet2",
                                "isSelectable": false
                            },
                            {
                                "isOption": true,
                                "takeawayPrice": "0",
                                "isSelectable": false,
                                "priName": "少",
                                "dineInPrice": "0",
                                "hasParent": true,
                                "secName": "Less Sweet",
                                "id": "Option_sweet3"
                            }
                        ],
                        "parent": [
                            "SubItem_7",
                            "SubItem_8",
                            "SubItem_9",
                            "SubItem_11",
                            "SubItem_13"
                        ]
                    },
                    {
                        "content": [
                            {
                                "isSelectable": true,
                                "id": "option_parent_cold_hot",
                                "secName": "Cold/Hot",
                                "isOption": true,
                                "maxSelection": 1,
                                "minSelection": 0,
                                "priName": "冷/熱"
                            },
                            {
                                "priName": "冷",
                                "secName": "Cold",
                                "hasParent": true,
                                "isOption": true,
                                "isSelectable": false,
                                "dineInPrice": "2",
                                "id": "option11"
                            },
                            {
                                "secName": "Hot",
                                "isSelectable": false,
                                "hasParent": true,
                                "isOption": true,
                                "priName": "熱",
                                "id": "option12"
                            }
                        ],
                        "parent": [
                            "Item_6"
                        ]
                    }
                ]
            },
            "optionStacks": [
                {
                    "content": [
                        {
                            "isSelectable": true,
                            "id": "option_parent_cold_hot",
                            "secName": "Cold/Hot",
                            "isOption": true,
                            "maxSelection": 1,
                            "minSelection": 0,
                            "priName": "冷/熱"
                        },
                        {
                            "secName": "Hot",
                            "isSelectable": false,
                            "hasParent": true,
                            "isOption": true,
                            "priName": "熱",
                            "id": "option12"
                        }
                    ],
                    "parent": [
                        "Item_6"
                    ]
                },
                {
                    "content": [],
                    "parent": [
                        "SubItem_7",
                        "SubItem_8",
                        "SubItem_9",
                        "SubItem_11",
                        "SubItem_13"
                    ]
                },
                {
                    "content": [],
                    "parent": [
                        "Item_6"
                    ]
                }
            ]
        }
    ],
    "id": "ae9da301-b705-445c-a354-d88a59261bf6",
    "specialInstruction": ""
}
export const orderDetailsContext = React.createContext();
export default useOrderDetail;



