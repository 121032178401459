import React, {useState, useRef, useEffect, useContext} from 'react';
import { Formik, Field, Form } from 'formik';
import '../style/Root.css';
import './Addons.css';
import { HeadBar } from '../Elements';
import AddonsNew from './AddonsNew';
import { widthContext } from '../../hooks/UseWindowWidth';
import { languageContext } from '../../hooks/UseLanguage';
import useAddons from '../../hooks/UseAddons';


function Addons (props) {
    const lang = useContext(languageContext);
    const isWebView = useContext(widthContext);
    const {meal, addOrderDetail, clickedCategory, itemStacks, page,resetSelectedOrderDetail, selectedOrderDetail, closeAddons, style} = props;

    const executeScroll = () => {
        // const menu = document.getElementById("app-menu");
        // const y = clickedCategory.current.offsetTop  - 70;
        // menu.scrollTo({top: y, behavior: 'smooth'});
    };
    return (
    (isWebView)? 
        <div class="modal " style={style.web}>
            <div class="modal-content c:row">
                <div class="modal-head py:sm">
                    <div 
                    onClick={()=>{
                        closeAddons();

                        resetSelectedOrderDetail();
                    }} 
                    class="head-btn close-btn bg:grey">
                            <i class="f:icon f:grey material-icons-outlined">
                                close
                            </i>
                    </div>
                </div>
                <div id="app-addons" class="web-addons-body h:full w:full c:row p:sm gap:xs bg:grey r:sm">
                    <div class="w:full center border:bottom pb:xs">
                    <div className="c:row">
                        <h4>{meal&&meal[lang]}</h4>
                        {meal.secName&&
                        <h5 className="secondary-name text-align-center">
                            {meal.secName}
                        </h5>}
                    </div>
                    </div>
                    <AddonsNew
                    selectedOrderDetail={selectedOrderDetail}
                    style={style}
                    addOrderDetail={addOrderDetail}
                    meal={meal} 
                    itemStacks={itemStacks} 
                    page={page}
                    lang={lang}
                    closeAddons={closeAddons} 
                    />
                </div>
            </div>
        </div>
        :<div id="app-addons" class="app-addons c:middle" style={style.mobile}> 
            <HeadBar 
            className="addons-head" 
            operation={()=>{
                closeAddons();
                executeScroll();
                resetSelectedOrderDetail();
            }} 
            title={meal&&meal[lang]}
            secTitle={meal.secName??false}
            />
            <div class="addons-body c:row h:full px:sm pb:sm ">
                <AddonsNew
                selectedOrderDetail={selectedOrderDetail}
                style={style}
                addOrderDetail={addOrderDetail}
                meal={meal} 
                itemStacks={itemStacks} 
                page={page}
                lang={lang}
                closeAddons={closeAddons} 
                />
            </div>
        </div>
    );
}

export default Addons;