import React, { useContext } from 'react';
import date from 'date-and-time';
import '../style/Root.css';
import './OrderReview.css';
import {HeadBar} from '../Elements';
import { widthContext } from '../../hooks/UseWindowWidth';
import { languageContext } from '../../hooks/UseLanguage';
import { dataContext } from '../../hooks/UseData';
import { priceBaseContext } from '../../hooks/UsePriceBase';
import mealFactory from '../../factory/mealFactory';
import orderDetailFactory from '../../factory/orderDetailFactory';


function OrderReview(props){
    const dineInDetail = useContext(dataContext);
    const isWebView = useContext(widthContext);
    const lang = useContext(languageContext);
    const priceBase = useContext(priceBaseContext);
    const { closeOrderReview, style} = props;
    return (
        (isWebView)?
            <div class="modal " style={{...style.web, "zIndex": "2"}}>
            <div class="modal-content c:row h:full p:sm">
                <div class="modal-head py:sm">
                    <div onClick={closeOrderReview} class="head-btn close-btn bg:grey">
                        <i class="f:icon f:grey material-icons-outlined">
                            close
                        </i>
                    </div>
                </div>
                <OrderReviewBody props={props} isWebView={isWebView} priceBase={priceBase} lang={lang} dineInDetail={dineInDetail} style={style.web}/>
            </div>
        </div>
        :<div class="app-order-review" style={style.mobile}>
            <HeadBar className="order-review-head" operation={closeOrderReview} title="已下單餐點"/>
            <OrderReviewBody props={props} isWebView={isWebView} priceBase={priceBase} lang={lang} dineInDetail={dineInDetail} style={style.mobile}/>
        </div>
    );
}
function roundUp(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.ceil(value * multiplier) / multiplier;
}
function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
}

export const OrderReviewBody = React.memo(({isWebView, props, priceBase, lang, dineInDetail})=>{
    const {closeOrderReview, style, ordered} = props;
    let myOrderDetailsList = [];
    const orderDetailsList = ordered;
    for (const orderDetail of orderDetailsList){
        if(myOrderDetailsList.some(obj=>obj.id === orderDetail.id)){
            const targetIndex = myOrderDetailsList.findIndex(obj=>obj.id === orderDetail.id);
            const copy = myOrderDetailsList[targetIndex];
            myOrderDetailsList[targetIndex] = {...copy, quantity: copy.quantity + 1};
        }else{
            myOrderDetailsList.push({...orderDetail, quantity: 1});
        }
    }
    const mealFC = mealFactory();
    const detailFC = orderDetailFactory(priceBase);
    const subTotal = myOrderDetailsList.reduce((total, orderDetail)=>total + detailFC.getOrderDetailTotalPrice(orderDetail)*orderDetail.quantity, 0);
    const totalQuantity = myOrderDetailsList.reduce((quantity, orderDetail)=>quantity + orderDetail.quantity, 0);
    const dineInExpiredTime = dineInDetail.order.dineInExpiredTime? date.format(new Date(dineInDetail.order.dineInExpiredTime), 'YYYY-MM-DD HH:mm'):"";
    const isDiscountOn = dineInDetail.defaultCharge;
    const serviceChargePercentage = isDiscountOn? dineInDetail.serviceCharge:0;
    const displaySubTotal = fixed(subTotal, 1);
    const serviceCharge = fixed(subTotal*serviceChargePercentage/100, 1);
    const totalPrice = fixed(serviceCharge+subTotal,1);
    // const serviceCharge = Math.ceil(subTotal*dineInDetail.serviceCharge/100);
    return(
    <div className={`order-review-wrap ${isWebView&&"web-order-review-wrap"}`}>
        <div className={`order-review-body h:full w:full c:row c:middle px:sm py:xs gap:sm r:sm`}>
            <div class="review-detail c:row w:full gap:sm flex:1">
                <div className="head-bar"></div>
                <div className="align-self-start table-info mb:xs">
                    <h5>人數： {dineInDetail.order.numberOfPeople}</h5>
                    <h5>枱號： {dineInDetail.order.tableDisplayName}</h5>
                </div>
                {myOrderDetailsList.map((orderDetail, index)=>{
                    const orderPrice = fixed(detailFC.getOrderDetailTotalPrice(orderDetail)*orderDetail.quantity, 1);
                    return (
                        <div key={`cart-item-card-${index}`} className="cart-item-card cart-item-card-disabled p:xs">
                            <div className="c:col c:between mb:xs c:middle">
                                <h6>{`${orderDetail.quantity}x`}&nbsp;</h6>
                                <h6 className="flex:1">{orderDetail.meal[lang]}</h6>
                                <p>${orderDetail.meal[priceBase]}</p>
                            </div>
                            {orderDetail&&orderDetail.itemDetails.map((itemDetail, index)=>{
                                {/* console.log(itemDetail); */}
                                if(!itemDetail) return null;
                                const itemStack = itemDetail.itemStack;
                                const optionStacks = itemDetail.optionStacks;
                                const isStandardItem = mealFC.isStandardItem(itemStack);
                                const item = isStandardItem? mealFC.getItemParent(itemStack):mealFC.getItemChildren(itemStack)[0];
                                const itemChildPrice = item? parseFloat(item[priceBase]):0;
                                return(
                                    <div key={`cart-item-${index}`} className="cart-item-detail c:row">
                                        {item&&<div className="c:col c:between">
                                            <h6 style={{visibility: "hidden"}}>{`${orderDetail.quantity}x `}&nbsp;</h6>
                                            <p className="flex:1">{item[lang]}</p>
                                            <p>{itemChildPrice>0&&`+$${itemChildPrice}`}</p>
                                        </div>}
                                        {optionStacks.map(optionStack=>{
                                            const optionParent = mealFC.getOptionParent(optionStack);
                                            const optionChild = mealFC.getItemChildren(optionStack)[0];
                                            const optionIsExist = optionParent&&optionChild;
                                            if(!optionIsExist) return null;
                                            const optionChildPrice = (optionIsExist&&optionChild[priceBase])? parseFloat(optionChild[priceBase]):0;
                                            return (
                                                <div className="c:col c:between">
                                                    <div className="c:col flex:1">
                                                        <h6 style={{visibility: "hidden"}}>{`${orderDetail.quantity}x`}&nbsp;</h6>
                                                        <p >{optionIsExist&&`(${optionParent[lang]}: ${optionChild[lang]})`}</p>
                                                    </div>
                                                    <p>{optionChildPrice>0&&`+$${optionChildPrice}`}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                            );
                            })}
                            <div className="c:col c:between">
                                <h6> {orderDetail.specialInstruction&& `《 特別要求： ${orderDetail.specialInstruction}》`}</h6>
                                <h6>${orderPrice}</h6>
                            </div>
                        </div>
                    );
                })}
                <div className="sized-box-checkout"/>
            </div>
            
            <div className="order-review-overview c:row w:full gap:sm p:sm">
                <div className="price-overview flex:1">
                    <h5>{totalQuantity} 項食物</h5>
                    <h5 className="title">小計</h5>
                    <h5 className="price">${displaySubTotal}</h5>
                    {serviceChargePercentage>0&&<div/>}
                    {serviceChargePercentage>0&&<h5 className="title">服務費 ({serviceChargePercentage}%)</h5>}
                    {serviceChargePercentage>0&&<h5 className="price">${serviceCharge}</h5>}
                    <div/>
                    <h5 className="title">總共</h5>
                    <h5 className="price">${totalPrice}</h5>
                </div>
            </div>
        </div>
    </div>
    );
});

// //priceBase = "takewayPrice"||"dineInPrice"
// const findMealTotalPrice = (orderDetail, priceBase) => {
//     const meal = orderDetail.meal;
//     const itemDetails = orderDetail.itemDetails;
//     let mealTotalPrice = parseFloat(meal[priceBase]);

//     for (const itemDetail of itemDetails){
//         for(const [key, item] of Object.entries(itemDetail.itemStack.content)){
//             if(item.hasParent&&item[priceBase]) mealTotalPrice += parseFloat(item[priceBase]);
//             console.log(parseFloat(item[priceBase]));
//         }
//     }
//     console.log(parseFloat(meal[priceBase]));
//     return mealTotalPrice;
// }


// const findOrderTotalPrice = (orderDetail, priceBase) => {
//     const meal = orderDetail.meal;
//     const itemDetails = orderDetail.itemDetails;
//     let mealTotalPrice = parseFloat(meal[priceBase]);

//     for (const itemDetail of itemDetails){
//         for(const [key, item] of Object.entries(itemDetail.itemStack.content)){
//             if(item.hasParent&&item[priceBase]) mealTotalPrice += parseFloat(item[priceBase]);
//             console.log(parseFloat(item[priceBase]));
//         }
//     }
//     console.log(parseFloat(meal[priceBase]));
//     return mealTotalPrice;
// }


export default OrderReview;