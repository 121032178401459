import React, { useEffect, useState, useContext } from "react";

const useLanguage = (language) => {
    const [currentLang, setCurrentLang] = useState(language);

    const changeLanguage = (change) => {
        setCurrentLang(change);
    }

    return [currentLang, changeLanguage];
};

export const languageContext = React.createContext();

export default useLanguage;