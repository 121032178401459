import axios from "axios";
import Routes from "../routes/Routes";

export async function getDineInDetail (token) {
    try{
        // console.log(refId);
        // const mytoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWZJZCI6IjBhYmMyNGRjLTJhMGEtNGJmNy1iZGQ1LTYzMGFhMjM1NGY4OCIsImFkbWluSWQiOiJRaUVnc0VrQ0ZlWWZWdGhUOVVOU3NadmVpem0yIiwicmVzdGF1cmFudElkIjoiS3hnWHVpTWlKYVRqcUhBc0pGM2ciLCJkYXlJZCI6InZINFJ2dEZENm9qaFcxNnN0a21QIiwiaWF0IjoxNjMyMjE4OTczLCJleHAiOjE2MzIyNjIxNzN9.nrIlYcn5dA9YDvaHERi-OG9DnLd0ORZrkezAYgjD_UM";
        const res = await axios.post(Routes.url + '/getDineInDetailByRefId', {token: token});
        const dineInData = JSON.parse(JSON.stringify(res));
        const dineInDetail = dineInData.data;
        for (const key in dineInDetail.menu.listOfCategories){
            const category = dineInDetail.menu.listOfCategories[key]
            if (category.showInWebDineIn === false) {
                delete dineInDetail.menu.listOfCategories[key]
            }
        }
        return [dineInDetail, null];

    }catch(error){
        return [null, JSON.stringify(error.response)];
    }
}