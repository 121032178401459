const mealFactory = (itemStacks) => {
    if (itemStacks && itemStacks.length > 0){
        itemStacks.sort((a,b)=>a.omniIndex - b.omniIndex);
    }
    const getItemDetailsLength = () => {
        return itemStacks.map(itemStack=>Object.values(itemStack.content).find(item=>!item.hasParent))
        .reduce((total, item)=> total+item.maxSelection, 0);
    }
    const isStandardItem = (itemStack) => {
        return Object.values(itemStack.content).some(item=>!item.hasParent&&!item.isSelectable);
    }
    const getItemParentByStackId = (stackId) => {
        return Object.entries(itemStacks.find(itemStack=>itemStack.id === stackId).content)
        .find(([key, item])=>!item.hasParent)[1];
    };
    const getItemChildrenByStackId = (stackId) => {
        return Object.entries(itemStacks.find(itemStack=>itemStack.id === stackId).content)
        .filter(([key, item])=>item.hasParent).map(arr=>arr[1]);
    };
    const getStandardItem = (itemStack) => {
        return Object.values(itemStack.content).find(item=>!item.hasParent&&!item.isSelectable);
    }
    const getStandardItemWithIndex = (itemStack) => {
        return {
            standardItem: Object.values(itemStack.content).find(item=>!item.hasParent&&!item.isSelectable),
            index: Object.values(itemStack.content).findIndex(item=>!item.hasParent&&!item.isSelectable)
        };
    }
    const getItemParent = (itemStack) => {
        return Object.values(itemStack.content).find(item=>!item.hasParent);
    };
    const getItemChildren = (itemStack) => {
        return Object.values(itemStack.content).filter(item=>item.hasParent).sort((a,b)=>a.omniIndex - b.omniIndex);
    };
    const getOptionParent = (optionStack) => {
        return optionStack.content.find(obj=>obj.isSelectable);
    }
    const getOptionChildren = (optionStack) => {
        return optionStack.content.filter(obj=>!obj.isSelectable);
    }
    const getItemStackMaxSelection = (itemStack) => {
        return getItemParent(itemStack).maxSelection;
    }
    const getItemStackMinSelection = (itemStack) => {
        return getItemParent(itemStack).minSelection;
    }
    const createItemDetail = (stackId, item, myItemStack) => {
        const targetStack = itemStacks.find(itemStack=>itemStack.id === stackId);
        const targetParent = Object.entries(targetStack.content).find(([key, item])=>!item.hasParent)[1];
        // const targetParent = getItemParent(targetStack);
        const initOptionStacks = myItemStack.optionStacks.map(optionStack=>({...optionStack, content: []}));
        if(item){
            return {itemStack: {...targetStack, content: {[targetParent.id]: targetParent, [item.id]: item}}, optionStacks: initOptionStacks};
        }else{
            return {itemStack: {...myItemStack}, optionStacks: initOptionStacks};
        }
    };
    // const getOrderDetailTotalPrice = (orderDetail ,priceBase) => {
    //     let total = parseFloat(orderDetail.meal[priceBase]);
    //     for (const itemDetail of orderDetail.itemDetails){
    //         const itemStack = itemDetail.itemStack;
    //         const isStandardItem = myMeal.isStandardItem(itemStack);
    //         const itemChild = !isStandardItem&&myMeal.getItemChildren(itemStack)[0];
    //         if(isStandardItem){
    //             continue;
    //         }
    //         total+=getPrice(itemChild);

    //         for (const optionStack of itemDetail.optionStacks){
    //             if(!optionStack.content.length) continue;
    //             const optionChild = myMeal.getOptionChildren(optionStack)[0];
    //             total+=getPrice(optionChild);
    //         }
    //     }

    //     return total;
    // };
    return {
        itemStacks,
        getItemParent,
        getItemChildren,
        createItemDetail,
        getItemDetailsLength,
        getItemStackMaxSelection,
        getItemStackMinSelection,
        getOptionParent,
        getOptionChildren,
        isStandardItem
    }
}

export default mealFactory;